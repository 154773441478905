* {
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}

.black-border {
    border: 2px solid ;
}

.Box {
    position: relative;
    height: 30px;
    width: 90%;
    margin: 5%;
    padding: 1% 1% 1% 1%;
    
    display: table-cell;
    align-content: center;
    border-radius: 190px;
}

.DraggableWrapper{
    height: 30px;
}

.Draggable {
    position: relative;
    z-index: 2;
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;

}

.FillBar{
    position: absolute;
    z-index: 1;
    top: 4px;
    display: block;
    float: left;
    height: 30px;
    border-radius: 190px;
}
